import type { TaxBracket } from '~/types/tax'

export default (income: number, brackets: TaxBracket[]): number => {
    let tax = 0
    let previousThreshold = 0

    for (const bracket of brackets) {
        if (income > bracket.threshold) {
            tax += (bracket.threshold - previousThreshold) * bracket.rate
            previousThreshold = bracket.threshold
        } else {
            tax += (income - previousThreshold) * bracket.rate
            break
        }
    }

    return tax
}