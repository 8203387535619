import type { TaxBracket } from '~/types/tax'

interface TaxInfo {
    federal: TaxBracket[];
    provincial: { [province: string]: TaxBracket[] };
    cpp?: {
        rate: number;
        maxContribution: number;
        basicExemption: number;
    };
    qpp?: {
        baseRate: number;
        additionalRate: number;
        maxPensionableEarnings: number;
        basicExemption: number;
    };
    ei: {
        rate: number;
        maxContribution: number;
    };
}

const taxInfo: TaxInfo = {
    federal: [
        { rate: 0, threshold: 15705 },
        { rate: 0.15, threshold: 53359 },
        { rate: 0.205, threshold: 106717 },
        { rate: 0.26, threshold: 165430 },
        { rate: 0.29, threshold: 235675 },
        { rate: 0.33, threshold: Infinity },
    ],
    provincial: {
        ON: [
            { rate: 0, threshold: 12399 },
            { rate: 0.0505, threshold: 46226 },
            { rate: 0.0915, threshold: 92454 },
            { rate: 0.1116, threshold: 150000 },
            { rate: 0.1216, threshold: 220000 },
            { rate: 0.1316, threshold: Infinity },
        ],
        QC: [
            { rate: 0, threshold: 17000 },
            { rate: 0.15, threshold: 49280 },
            { rate: 0.20, threshold: 98560 },
            { rate: 0.24, threshold: 119910 },
            { rate: 0.2575, threshold: Infinity },
        ],
        BC: [
            { rate: 0, threshold: 11869 },
            { rate: 0.0506, threshold: 45142 },
            { rate: 0.077, threshold: 90287 },
            { rate: 0.105, threshold: 104835 },
            { rate: 0.1229, threshold: 150000 },
            { rate: 0.147, threshold: 220000 },
            { rate: 0.168, threshold: Infinity },
        ],
        AB: [
            { rate: 0, threshold: 21003 },
            { rate: 0.10, threshold: 142292 },
            { rate: 0.12, threshold: 170751 },
            { rate: 0.13, threshold: 227668 },
            { rate: 0.14, threshold: 341502 },
            { rate: 0.15, threshold: Infinity },
        ],
        MB: [
            { rate: 0, threshold: 10655 },
            { rate: 0.108, threshold: 36295 },
            { rate: 0.1275, threshold: 78565 },
            { rate: 0.174, threshold: Infinity },
        ],
        SK: [
            { rate: 0, threshold: 16615 },
            { rate: 0.105, threshold: 46773 },
            { rate: 0.125, threshold: 133638 },
            { rate: 0.145, threshold: Infinity },
        ],
        NS: [
            { rate: 0, threshold: 11881 },
            { rate: 0.0879, threshold: 29590 },
            { rate: 0.1495, threshold: 59180 },
            { rate: 0.1667, threshold: 93000 },
            { rate: 0.175, threshold: 150000 },
            { rate: 0.21, threshold: Infinity },
        ],
        NB: [
            { rate: 0, threshold: 12593 },
            { rate: 0.094, threshold: 47915 },
            { rate: 0.148, threshold: 95829 },
            { rate: 0.1652, threshold: 154382 },
            { rate: 0.1784, threshold: 222000 },
            { rate: 0.203, threshold: Infinity },
        ],
        PE: [
            { rate: 0, threshold: 11500 },
            { rate: 0.098, threshold: 31984 },
            { rate: 0.138, threshold: 63969 },
            { rate: 0.167, threshold: Infinity },
        ],
        NL: [
            { rate: 0, threshold: 10163 },
            { rate: 0.087, threshold: 41777 },
            { rate: 0.145, threshold: 83554 },
            { rate: 0.158, threshold: 148695 },
            { rate: 0.173, threshold: 186601 },
            { rate: 0.183, threshold: 500000 },
            { rate: 0.198, threshold: Infinity },
        ],
        YT: [
            { rate: 0, threshold: 15705 },
            { rate: 0.064, threshold: 53359 },
            { rate: 0.09, threshold: 106717 },
            { rate: 0.109, threshold: 165430 },
            { rate: 0.128, threshold: 500000 },
            { rate: 0.15, threshold: Infinity },
        ],
        NT: [
            { rate: 0, threshold: 16000 },
            { rate: 0.059, threshold: 45627 },
            { rate: 0.086, threshold: 91259 },
            { rate: 0.122, threshold: 142437 },
            { rate: 0.1405, threshold: Infinity },
        ],
        NU: [
            { rate: 0, threshold: 16989 },
            { rate: 0.04, threshold: 49238 },
            { rate: 0.07, threshold: 98476 },
            { rate: 0.09, threshold: 172602 },
            { rate: 0.115, threshold: Infinity },
        ],
    },
    cpp: {
        rate: 0.0595,
        maxContribution: 3867.50,
        basicExemption: 3500,
    },
    qpp: {
        baseRate: 0.054,
        additionalRate: 0.01,
        maxPensionableEarnings: 68500,
        basicExemption: 3500,
    },
    ei: {
        rate: 0.0158,
        maxContribution: 889.54,
    },
}

function calculateCPP(grossPay: number): number {
    if (taxInfo.cpp === undefined) return 0
    const { rate, maxContribution, basicExemption } = taxInfo.cpp
    const pensionableEarnings = Math.max(grossPay - basicExemption, 0)
    const contribution = Math.min(pensionableEarnings * rate, maxContribution)
    return contribution
}

function calculateQPP(grossPay: number): number {
    if (taxInfo.qpp === undefined) return 0

    const { baseRate, additionalRate, maxPensionableEarnings, basicExemption } = taxInfo.qpp
    const pensionableEarnings = Math.min(Math.max(grossPay - basicExemption, 0), maxPensionableEarnings - basicExemption)
    const baseContribution = pensionableEarnings * baseRate
    const additionalContribution = pensionableEarnings * additionalRate
    return baseContribution + additionalContribution
}

function calculateEI(grossPay: number): number {
    const { rate, maxContribution } = taxInfo.ei
    const contribution = Math.min(grossPay * rate, maxContribution)
    return contribution
}

export const calculateGrossEarningsCanada = (netPay: number, province: string, additionalTaxRate: number = 0, tolerance: number = 100, maxIterations: number = 100): number => {
    let grossPay = netPay * 1.25
    let iteration = 0

    while (iteration < maxIterations) {
        iteration++

        const calculatedNetPay = calculateNetEarningsCanada(grossPay, province, additionalTaxRate)

        const difference = netPay - calculatedNetPay

        if (Math.abs(difference) <= tolerance) {
            break
        }

        grossPay += difference / 2
    }

    if (iteration === maxIterations) {
        throw new Error("Unable to converge on a gross pay estimate. Please check the input values.")
    }

    return parseFloat(grossPay.toFixed(2))
}

export const calculateNetEarningsCanada = (grossPay: number, province: string, additionalTaxRate: number = 0): number => {
    const federalBrackets = taxInfo.federal
    const provincialBrackets = taxInfo.provincial[province]

    if (!provincialBrackets) throw new Error(`Unsupported province: ${province}`)

    const qppContribution = province === "QC" ? calculateQPP(grossPay) : calculateCPP(grossPay)
    const eiContribution = calculateEI(grossPay)
    const taxableIncome = grossPay - qppContribution - eiContribution

    const federalTax = calculateTax(taxableIncome, federalBrackets)
    const provincialTax = calculateTax(taxableIncome, provincialBrackets)
    const additionalTax = grossPay * additionalTaxRate

    const netPay = grossPay - federalTax - provincialTax - additionalTax - qppContribution - eiContribution

    return parseFloat(netPay.toFixed(2))
}
