type HolidaysByRegion = {
    [country: string]: {
        [region: string]: number;
    };
};

const holidaysByRegion: HolidaysByRegion = {
    "CA": {
        "AB": 9,
        "BC": 10,
        "MB": 8,
        "NB": 7,
        "NL": 7,
        "NS": 6,
        "NT": 10,
        "NU": 10,
        "ON": 9,
        "PE": 7,
        "QC": 8,
        "SK": 10,
        "YT": 10,
    },
    "US": {
        "AL": 13,
        "AK": 11,
        "AZ": 11,
        "AR": 11,
        "CA": 11,
        "CO": 11,
        "CT": 12,
        "DE": 12,
        "FL": 11,
        "GA": 12,
        "HI": 14,
        "ID": 11,
        "IL": 12,
        "IN": 12,
        "IA": 11,
        "KS": 11,
        "KY": 11,
        "LA": 12,
        "ME": 11,
        "MD": 12,
        "MA": 12,
        "MI": 11,
        "MN": 11,
        "MS": 12,
        "MO": 11,
        "MT": 11,
        "NE": 11,
        "NV": 12,
        "NH": 11,
        "NJ": 12,
        "NM": 11,
        "NY": 12,
        "NC": 11,
        "ND": 11,
        "OH": 11,
        "OK": 11,
        "OR": 11,
        "PA": 11,
        "RI": 12,
        "SC": 12,
        "SD": 11,
        "TN": 11,
        "TX": 11,
        "UT": 11,
        "VT": 11,
        "VA": 12,
        "WA": 11,
        "WV": 11,
        "WI": 11,
        "WY": 11,
        "DC": 12,
        "AS": 12,
        "GU": 14,
        "MP": 12,
        "PR": 19,
        "VI": 14,
    },
    "GB": {
        "ENG": 8,
        "WLS": 8,
        "SCT": 9,
        "NIR": 10,
    },
    "NZ": {
        "NLD": 12,
        "AUK": 12,
        "WKO": 12,
        "BOP": 12,
        "GIS": 12,
        "HKB": 12,
        "TKI": 12,
        "MWT": 12,
        "WGN": 12,
        "TAS": 12,
        "NSN": 12,
        "MBH": 12,
        "WTC": 12,
        "CAN": 12,
        "OTA": 12,
        "STL": 12,
        "CIT": 12,
    },
    "AU": {
        "ACT": 12,
        "NSW": 11,
        "NT": 12,
        "QLD": 11,
        "SA": 12,
        "TAS": 11,
        "VIC": 13,
        "WA": 10,
    },
    "IE": {
        "LE": 10,
        "MU": 10,
        "CO": 10,
        "UL": 10,
    },
}

export default (country: string, region: string) => {
    return holidaysByRegion[country]?.[region] ?? null
}
