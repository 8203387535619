import type { TaxBracket } from '~/types/tax'

interface TaxInfo {
    federal: TaxBracket[];
    medicare: {
        standardRate: number;
        surchargeRate: number;
        surchargeThreshold: number;
    };
}

const taxInfo: TaxInfo = {
    federal: [
        { rate: 0, threshold: 18200 },
        { rate: 0.19, threshold: 45000 },
        { rate: 0.325, threshold: 120000 },
        { rate: 0.37, threshold: 180000 },
        { rate: 0.45, threshold: Infinity },
    ],
    medicare: {
        standardRate: 0.02,
        surchargeRate: 0.01,
        surchargeThreshold: 140000,
    },
}

function calculateMedicare(grossPay: number): number {
    const { standardRate, surchargeRate, surchargeThreshold } = taxInfo.medicare
    let levy = grossPay * standardRate

    if (grossPay > surchargeThreshold) {
        levy += (grossPay - surchargeThreshold) * surchargeRate
    }

    return levy
}

export const calculateGrossEarningsAustralia = (netPay: number, additionalTaxRate: number = 0, tolerance: number = 100, maxIterations: number = 100): number => {
    let grossPay = netPay * 1.25
    let iteration = 0

    while (iteration < maxIterations) {
        iteration++

        const calculatedNetPay = calculateNetEarningsAustralia(grossPay, additionalTaxRate)

        const difference = netPay - calculatedNetPay

        if (Math.abs(difference) <= tolerance) {
            break
        }

        grossPay += difference / 2
    }

    if (iteration === maxIterations) {
        throw new Error("Unable to converge on a gross pay estimate. Please check the input values.")
    }

    return parseFloat(grossPay.toFixed(2))
}

export const calculateNetEarningsAustralia = (grossPay: number, additionalTaxRate: number = 0): number => {
    const federalBrackets = taxInfo.federal

    const federalTax = calculateTax(grossPay, federalBrackets)
    const additionalTax = grossPay * additionalTaxRate
    const medicareLevy = calculateMedicare(grossPay)

    return grossPay - federalTax - additionalTax - medicareLevy
}