import type { TaxBracket } from '~/types/tax'

interface TaxInfo {
    incomeTax: TaxBracket[];
    accLevy: {
        rate: number;
        maxEarnings: number;
    };
}

const taxInfo: TaxInfo = {
    incomeTax: [
        { rate: 0.105, threshold: 14000 },
        { rate: 0.175, threshold: 48000 },
        { rate: 0.30, threshold: 70000 },
        { rate: 0.33, threshold: 180000 },
        { rate: 0.39, threshold: Infinity },
    ],
    accLevy: {
        rate: 0.0146,
        maxEarnings: 139384,
    },
}

function calculateACCLevy(grossPay: number): number {
    const { rate, maxEarnings } = taxInfo.accLevy
    return Math.min(grossPay, maxEarnings) * rate
}

export const calculateGrossEarningsNewZealand = (netPay: number, additionalTaxRate: number = 0, tolerance: number = 100, maxIterations: number = 100): number => {
    let grossPay = netPay * 1.25
    let iteration = 0

    while (iteration < maxIterations) {
        iteration++

        const calculatedNetPay = calculateNetEarningsNewZealand(grossPay, additionalTaxRate)
        const difference = netPay - calculatedNetPay

        if (Math.abs(difference) <= tolerance) {
            break
        }

        grossPay += difference / 2
    }

    if (iteration === maxIterations) {
        throw new Error("Unable to converge on a gross pay estimate. Please check the input values.")
    }

    return parseFloat(grossPay.toFixed(2))
}

export const calculateNetEarningsNewZealand = (grossPay: number, additionalTaxRate: number = 0): number => {
    const incomeTaxBrackets = taxInfo.incomeTax

    const incomeTax = calculateTax(grossPay, incomeTaxBrackets)
    const accLevy = calculateACCLevy(grossPay)
    const additionalTax = grossPay * additionalTaxRate

    return grossPay - incomeTax - accLevy - additionalTax
}