import type { Option } from '~/types'

const marketingChannelOptions: Option<string>[] =
    [
        { label: 'Search Engine (e.g., Google, Bing)', value: 'SearchEngine' },
        { label: 'Social Media (e.g., Facebook, Instagram, LinkedIn)', value: 'SocialMedia' },
        { label: 'Online Advertisement', value: 'OnlineAdvertisement' },
        { label: 'Referral from a Friend or Colleague', value: 'Referral' },
        { label: 'Existing Customer', value: 'ExistingCustomer' },
        { label: 'Event or Tradeshow', value: 'EventTradeshow' },
        { label: 'Email Campaign', value: 'EmailCampaign' },
        { label: 'Flyers or Printed Ads', value: 'FlyersPrintedAds' },
        { label: 'Review Websites (e.g., Yelp, Trustpilot)', value: 'ReviewWebsites' },
        { label: 'Direct Mail', value: 'DirectMail' },
    ].sort(() => Math.random() - 0.5)
marketingChannelOptions.push({ label: 'Other', value: 'Other' })

export default marketingChannelOptions