import countries from '~/lib/extended-countries'
import type { CountryOption, CountryCodes } from '~/types'

export default (countryCode: CountryCodes | undefined = 'US') => {
    const country = countries.find(c => c.value === countryCode) ?? countries.find(c => c.value === 'US')!

    return (value: number, precision?: number) =>
        formatter(country, value, precision)
}

const formatter = (country: CountryOption, value: number, precision: number = 0): string => {
    try {
        return new Intl.NumberFormat(country.locale, {
            style: 'percent',
            minimumFractionDigits: precision,
            maximumFractionDigits: precision,
            useGrouping: true,
        }).format(value)
    } catch (e) {
        captureError(e)
        return `${value.toFixed(precision)}%`.trim()
    }
}