export const calculateGrossEarnings = (netPay: number, country: string, region: string, additionalTaxRate: number = 0, tolerance: number = 0.01, maxIterations: number = 100): number => {
    if (country === "AU") return calculateGrossEarningsAustralia(netPay, additionalTaxRate, tolerance, maxIterations)
    if (country === "CA") return calculateGrossEarningsCanada(netPay, region, additionalTaxRate, tolerance, maxIterations)
    if (country === "IE") return calculateGrossEarningsIreland(netPay, additionalTaxRate, tolerance, maxIterations)
    if (country === "NZ") return calculateGrossEarningsNewZealand(netPay, additionalTaxRate, tolerance, maxIterations)
    if (country === "GB") return calculateGrossEarningsUnitedKingdom(netPay, region, additionalTaxRate, tolerance, maxIterations)
    if (country === "US") return calculateGrossEarningsUnitedStates(netPay, region, additionalTaxRate, tolerance, maxIterations)
    if (country === "XX") return netPay / 0.7

    throw new Error(`Unsupported country: ${country}`)
}

export const calculateNetEarnings = (grossPay: number, country: string, region: string, additionalTaxRate: number = 0): number => {
    if (country === "AU") return calculateNetEarningsAustralia(grossPay, additionalTaxRate)
    if (country === "CA") return calculateNetEarningsCanada(grossPay, region, additionalTaxRate)
    if (country === "IE") return calculateNetEarningsIreland(grossPay, additionalTaxRate)
    if (country === "NZ") return calculateNetEarningsNewZealand(grossPay, additionalTaxRate)
    if (country === "GB") return calculateNetEarningsUnitedKingdom(grossPay, region, additionalTaxRate)
    if (country === "US") return calculateNetEarningsUnitedStates(grossPay, region, additionalTaxRate)
    if (country === "XX") return grossPay * 0.7

    throw new Error(`Unsupported country: ${country}`)
}