import type { TaxBracket } from '~/types/tax'

interface TaxInfo {
    incomeTax: TaxBracket[];
    usc: TaxBracket[];
    prsi: {
        rate: number;
        threshold: number;
    };
}

const taxInfo: TaxInfo = {
    incomeTax: [
        { rate: 0, threshold: 8875 },
        { rate: 0.2, threshold: 40000 },
        { rate: 0.4, threshold: Infinity },
    ],
    usc: [
        { rate: 0.005, threshold: 12012 },
        { rate: 0.02, threshold: 22192 },
        { rate: 0.045, threshold: 70044 },
        { rate: 0.08, threshold: Infinity },
    ],
    prsi: {
        rate: 0.04,
        threshold: 352,
    },
}

function calculatePRSI(grossPay: number): number {
    const { rate, threshold } = taxInfo.prsi
    return grossPay > threshold ? grossPay * rate : 0
}

export const calculateGrossEarningsIreland = (netPay: number, additionalTaxRate: number = 0, tolerance: number = 100, maxIterations: number = 100): number => {
    let grossPay = netPay * 1.25
    let iteration = 0

    while (iteration < maxIterations) {
        iteration++

        const calculatedNetPay = calculateNetEarningsIreland(grossPay, additionalTaxRate)

        const difference = netPay - calculatedNetPay

        if (Math.abs(difference) <= tolerance) {
            break
        }

        grossPay += difference / 2
    }

    if (iteration === maxIterations) {
        throw new Error("Unable to converge on a gross pay estimate. Please check the input values.")
    }

    return parseFloat(grossPay.toFixed(2))
}

export const calculateNetEarningsIreland = (grossPay: number, additionalTaxRate: number = 0): number => {
    const incomeTaxBrackets = taxInfo.incomeTax
    const uscBrackets = taxInfo.usc

    const incomeTax = calculateTax(grossPay, incomeTaxBrackets)
    const usc = calculateTax(grossPay, uscBrackets)
    const prsi = calculatePRSI(grossPay)
    const additionalTax = grossPay * additionalTaxRate

    return grossPay - incomeTax - usc - prsi - additionalTax
}