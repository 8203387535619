import type { TaxBracket } from '~/types/tax'

interface TaxInfo {
    incomeTax: { [region: string]: TaxBracket[] };
    nic: {
        employee: TaxBracket[];
    };
}

const taxInfo: TaxInfo = {
    incomeTax: {
        ENG: [
            { rate: 0, threshold: 12570 },
            { rate: 0.2, threshold: 50270 },
            { rate: 0.4, threshold: 125140 },
            { rate: 0.45, threshold: Infinity },
        ],
        SCT: [
            { rate: 0, threshold: 12570 },
            { rate: 0.19, threshold: 14732 },
            { rate: 0.2, threshold: 25688 },
            { rate: 0.21, threshold: 43662 },
            { rate: 0.42, threshold: 125140 },
            { rate: 0.47, threshold: Infinity },
        ],
        WLS: [
            { rate: 0, threshold: 12570 },
            { rate: 0.2, threshold: 50270 },
            { rate: 0.4, threshold: 125140 },
            { rate: 0.45, threshold: Infinity },
        ],
        NIR: [
            { rate: 0, threshold: 12570 },
            { rate: 0.2, threshold: 50270 },
            { rate: 0.4, threshold: 125140 },
            { rate: 0.45, threshold: Infinity },
        ],
    },
    nic: {
        employee: [
            { rate: 0, threshold: 12570 },
            { rate: 0.12, threshold: 50270 },
            { rate: 0.02, threshold: Infinity },
        ],
    },
}

function calculateNIC(grossPay: number): number {
    return calculateTax(grossPay, taxInfo.nic.employee)
}

export const calculateGrossEarningsUnitedKingdom = (netPay: number, region: string, additionalTaxRate: number = 0, tolerance: number = 100, maxIterations: number = 100): number => {
    let grossPay = netPay * 1.25
    let iteration = 0

    while (iteration < maxIterations) {
        iteration++

        const calculatedNetPay = calculateNetEarningsUnitedKingdom(grossPay, region, additionalTaxRate)
        const difference = netPay - calculatedNetPay

        if (Math.abs(difference) <= tolerance) {
            break
        }

        grossPay += difference / 2
    }

    if (iteration === maxIterations) {
        throw new Error("Unable to converge on a gross pay estimate. Please check the input values.")
    }

    return parseFloat(grossPay.toFixed(2))
}

export const calculateNetEarningsUnitedKingdom = (grossPay: number, region: string, additionalTaxRate: number = 0): number => {
    const incomeTaxBrackets = taxInfo.incomeTax[region]

    if (!incomeTaxBrackets) {
        throw new Error(`Unsupported region: ${region}`)
    }

    const nic = calculateNIC(grossPay)

    const incomeTax = calculateTax(grossPay, incomeTaxBrackets)
    const additionalTax = grossPay * additionalTaxRate

    return grossPay - incomeTax - nic - additionalTax
}