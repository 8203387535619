import type { Option } from '~/types'

export default [
    { "label": "Client Loyalty", "value": "client_loyalty" },
    { "label": "Acquiring New Clients", "value": "client_acquision" },
    { "label": "Client Communication", "value": "client_communication" },
    { "label": "Managing Client Expectations", "value": "client_expectations" },
    { "label": "Staff Management", "value": "staff_management" },
    { "label": "Staff Communication", "value": "staff_communication" },
    { "label": "Staff Training", "value": "staff_training" },
    { "label": "Staff Retention", "value": "staff_retention" },
    { "label": "Hiring", "value": "staff_hiring" },
    { "label": "Quality Control", "value": "quality_control" },
    { "label": "Scheduling", "value": "scheduling" },
    { "label": "Business Administration", "value": "business_adminstration" },
    { "label": "Growing Your Business", "value": "business_expansion" },
    { "label": "Cashflow Management", "value": "cashflow_management" },
    { "label": "Expense Management", "value": "expense_management" },
    { "label": "Tax compliance", "value": "tax_compliance" },
    { "label": "Insurance", "value": "insurance" },
    { "label": "Pricing Strategy", "value": "pricing_strategy" },
    { "label": "Choosing the right equipment & supplies", "value": "choosing_equipment_supplies" },
    { "label": "Using the right tools/supplies for the job", "value": "using_right_tools_supplies" },
    { "label": "Other (tell us in the comments)", "value": "other" },
] as Option<string>[]