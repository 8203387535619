<template>
    <div>

        <h1
            class="flex flex-col mx-auto w-full max-w-7xl my-4 text-center sm:text-left"
            :class="[stepNumber ? 'title-3' : 'title-6']">
            {{ title }}
        </h1>

        <div v-if="isFutureStep('finished')" class="flex gap-4 items-center">
            <UProgress
                v-if="stepNumber"
                color="success"
                size="md"
                :value="stepNumber / totalSteps * 100"
                class="mb-4 flex-grow" />

            <UToggle
                v-if="!isFutureStep('required-earnings')"
                v-model="showHelp"
                on-icon="material-symbols:question-mark-rounded"
                off-icon="material-symbols:question-mark-rounded"
                class="mb-4"
                size="xl">
                <template #label>
                    <span class="text-sm">Show help</span>
                </template>
            </UToggle>

        </div>

        <UForm :state="state" :schema="v.safeParserAsync(formSchema)" @submit="handleSubmit">
            <div class="space-y-2 sm:space-y-4">
                <template v-if="step === 'intro'">
                    <p>
                        Pricing your cleaning services can be overwhelming, whether you're just starting out or looking
                        to refine your current rates. This step-by-step calculator is designed to help you determine the
                        perfect price for your cleaning services — tailored to your location, income goals, and business
                        needs. By considering your expenses, time off, work hours, and desired income, you’ll gain a
                        clear picture of how to set competitive and sustainable rates.
                    </p>

                    <p>
                        This tool goes beyond simple pricing—it’s built for residential cleaners and cleaning business
                        owners who want to:
                    </p>

                    <ul class="ml-8 list-disc space-y-1">
                        <li>
                            Understand the true cost of running their business.
                        </li>
                        <li>
                            Set rates that ensure profitability.
                        </li>
                        <li>
                            Factor in realistic expenses like supplies, travel, and taxes.
                        </li>
                        <li>
                            Account for personal goals like time off and work-life balance.
                        </li>
                    </ul>

                    <p>
                        Whether you're in the United States, Canada, or anywhere else in the world, this customizable calculator
                        will guide you through all the key considerations.
                    </p>
                </template>

                <template v-if="step === 'location'">
                    <p>
                        Your location impacts taxes, labor laws, and costs. By tailoring to your region, we provide
                        accurate pricing recommendations for your cleaning services.
                    </p>

                    <div class="grid gap-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-3">
                        <UFormGroup required label="Country" name="country">
                            <USelectMenu v-model="selectedCountry" label="Country" :options="countryOptions">
                                <template #label>
                                    <template v-if="!selectedCountry">
                                        <span class="text-gray-400">Select a country</span>
                                    </template>
                                    <template v-else>
                                        <UIcon v-if="selectedCountry.icon" :name="selectedCountry.icon" />
                                        <span class="truncate">{{ selectedCountry?.label }}</span>
                                    </template>
                                </template>
                            </USelectMenu>
                        </UFormGroup>

                        <UFormGroup :required="state.country !== 'XX'" :label="regionType" name="region">
                            <USelectMenu
                                v-model="selectedRegion"
                                :disabled="(!state.country || state.country === 'XX') && !regionOptions[state.country as string]?.length"
                                :options="regionOptions[state.country as string]">
                                <template #label>
                                    <template v-if="!selectedCountry">
                                        <span class="text-gray-400">Select a country first</span>
                                    </template>
                                    <template v-else-if="selectedCountry.value === 'XX'">
                                        <span class="text-gray-400">Not available</span>
                                    </template>
                                    <template v-else-if="!selectedRegion">
                                        <span class="text-gray-400">Select a region</span>
                                    </template>
                                    <template v-else>
                                        <span class="truncate">{{ selectedRegion?.label }}</span>
                                    </template>
                                </template>
                            </USelectMenu>
                        </UFormGroup>

                        <UFormGroup required label="City" name="city">
                            <UInput v-model="state.city" />
                        </UFormGroup>
                    </div>

                </template>

                <template v-if="step === 'required-earnings'">
                    <p>
                        This is the amount you need to take home after taxes and deductions — similar to a paycheck
                        you’d receive as an employee. Knowing this helps us calculate your required gross income to
                        ensure you meet your
                        personal financial needs.
                    </p>

                    <div class="grid gap-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-3">
                        <UFormGroup label="Monthly Net Earnings" name="requiredNetEarnings">
                            <URange
                                v-model.number="state.requiredNetEarnings"
                                :step="100"
                                :min="100"
                                :max="10000"
                                @change="handleRequiredNetEarningsBlur"/>

                            <template v-if="state.requiredNetEarnings" #hint>
                                {{ formatCurrency(state.requiredNetEarnings) }}/month
                            </template>

                            <template v-if="showHelp" #help>
                                The amount you take home after taxes and deductions have been subtracted from your
                                total earnings.
                            </template>
                        </UFormGroup>

                        <UFormGroup label="Annual Gross Earnings" name="requiredGrossEarnings">
                            <URange
                                v-model.number="state.requiredGrossEarnings"
                                :step="1"
                                :min="1"
                                :max="160000"
                                @change="handleRequiredGrossEarningsBlur"/>

                            <template v-if="state.requiredGrossEarnings" #hint>
                                {{ formatCurrency(state.requiredGrossEarnings) }}/year
                            </template>

                            <template v-if="showHelp" #help>
                                It’s the total amount you earn before taxes and deductions are subtracted.
                            </template>
                        </UFormGroup>

                        <UFormGroup label="Average Tax Rate" name="requiredTaxRate">
                            <URange
                                v-model.number="state.requiredTaxRate"
                                :step="0.01"
                                :min="0"
                                :max="60"
                                @change="handleRequiredTaxRateBlur"/>

                            <template v-if="state.requiredTaxRate" #hint>
                                {{ state.requiredTaxRate }}%
                            </template>

                            <template v-if="showHelp" #help>
                                This is your estimated tax rate based on your location. Your individual tax rate may
                                vary based on a variety of factors not considered in this calculator.
                            </template>
                        </UFormGroup>
                    </div>
                </template>

                <template v-if="step === 'target-earnings'">
                    <p>
                        Now that we understand the required amount you need to meet your financial needs, let’s think
                        bigger.
                        What is your ideal financial target? This could be the amount that allows you to not only cover
                        your essentials but also achieve your goals, enjoy your lifestyle, and plan for the future.
                    </p>

                    <p>
                        To help you get started, we’ve already added 10% to your previous goal. Feel free to adjust this
                        amount to better reflect your aspirations and financial goals.
                    </p>

                    <div class="grid gap-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-3">
                        <UFormGroup label="Monthly Net Earnings" name="targetNetEarnings">
                            <URange
                                v-model.number="state.targetNetEarnings"
                                :step="1"
                                :min="1"
                                :max="10000"
                                @blur="handleTargetNetEarningsBlur"/>

                            <template v-if="state.targetNetEarnings" #hint>
                                {{ formatCurrency(state.targetNetEarnings) }}/month
                            </template>

                            <template v-if="showHelp" #help>
                                The amount you take home after taxes and deductions have been subtracted from your
                                total earnings.
                            </template>
                        </UFormGroup>

                        <UFormGroup
                            v-if="state.targetNetEarnings"
                            label="Annual Gross Earnings"
                            name="targetGrossEarnings">
                            <URange
                                v-model.number="state.targetGrossEarnings"
                                :step="1"
                                :min="1"
                                :max="240000"
                                @blur="handleTargetGrossEarningsBlur"/>

                            <template v-if="state.targetGrossEarnings" #hint>
                                {{ formatCurrency(state.targetGrossEarnings) }}/year
                            </template>

                            <template v-if="showHelp" #help>
                                It’s the total amount you earn before taxes and deductions are subtracted.
                            </template>
                        </UFormGroup>

                        <UFormGroup
                            v-if="state.targetGrossEarnings"
                            label="Average Tax Rate"
                            name="targetTaxRate">
                            <URange
                                v-model.number="state.targetTaxRate"
                                :step="0.01"
                                :min="0"
                                :max="60"
                                @blur="handleTargetTaxRateBlur"/>

                            <template v-if="state.requiredTaxRate" #hint>
                                {{ state.targetTaxRate }}%
                            </template>

                            <template v-if="showHelp" #help>
                                This is your estimated tax rate based on your location. Your individual tax rate may
                                vary based on a variety of factors not considered in this calculator.
                            </template>
                        </UFormGroup>
                    </div>
                </template>

                <template v-if="step === 'time-off'">
                    <p>
                        Taking time off is essential for maintaining a healthy work-life balance, recharging, and
                        achieving long-term productivity. Whether it’s planning vacations, spending quality time with
                        loved ones, or simply stepping away to focus on your well-being, your time off is just as
                        important as your financial goals.
                    </p>

                    <p>
                        Let’s define what “time off” means to you and how much of it you’d like to prioritize in your
                        plans. This will help us align your financial targets with the lifestyle you envision.
                    </p>

                    <div class="grid gap-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-3">
                        <UFormGroup label="Vacation Days" name="vacationDays">
                            <URange
                                v-model.number="state.vacationDays"
                                :step="1"
                                :min="0"
                                :max="100"
                            />

                            <template #hint>
                                {{ state.vacationDays }} days
                            </template>

                            <template v-if="showHelp" #help>
                                Enter the number of vacation days you'd like to take each year. Consider what feels
                                restorative and achievable for your lifestyle and goals.
                            </template>
                        </UFormGroup>

                        <UFormGroup label="Holidays per Year" name="holidaysPerYear">
                            <URange
                                v-model.number="state.holidaysPerYear"
                                :step="1"
                                :min="0"
                                :max="100"
                            />

                            <template #hint>
                                {{ state.holidaysPerYear }} days
                            </template>

                            <template v-if="showHelp" #help>
                                Enter the number of public holidays and any religious or cultural holidays you plan to
                                take off each year.
                            </template>
                        </UFormGroup>

                        <UFormGroup label="Personal Days" name="personalDays">
                            <URange
                                v-model.number="state.personalDays"
                                :step="1"
                                :min="0"
                                :max="100"
                            />

                            <template #hint>
                                {{ state.personalDays }} days
                            </template>

                            <template v-if="showHelp" #help>
                                Enter the number of personal days you'd like to reserve each year for self-care,
                                unforeseen circumstances, or caring for others, such as young children or family members.
                            </template>
                        </UFormGroup>
                    </div>
                </template>

                <template v-if="step === 'work-schedule'">
                    <p>
                        Let’s take a closer look at how you structure your workday and week. Understanding your time
                        allocation will help create a balanced and realistic plan that aligns with your goals and
                        priorities. By setting clear expectations for your schedule, we can ensure your work remains
                        productive and sustainable.
                    </p>

                    <div class="grid gap-4 grid-cols-1 lg:grid-cols-2">

                        <div class="grid grid-cols-1 gap-4 lg:grid-cols-2 lg:col-span-full">
                            <UFormGroup label="How many days per week do you work?" name="workDaysPerWeek">
                                <URange
                                    v-model.number="state.workDaysPerWeek"
                                    :step="1"
                                    :min="1"
                                    :max="7"
                                />

                                <template #hint>
                                    {{ state.workDaysPerWeek }} days
                                </template>

                                <template v-if="showHelp" #help>
                                    Enter the number of days you plan to work each week. This includes all days dedicated to
                                    work, whether billable or non-billable tasks.
                                </template>
                            </UFormGroup>
                        </div>

                        <UFormGroup label="Time spent cleaning per day?" name="dailyCleaningSegments">
                            <URange
                                v-model.number="state.dailyCleaningSegments"
                                :step="1"
                                :min="0"
                                :max="12 * 60 / segmentSizeInMinutes"
                            />

                            <template #hint>
                                {{ getTaskTime(state.dailyCleaningSegments) }} ({{ formatPercentage((state.dailyCleaningSegments ?? 0)/totalWorkingSegments) }}%)
                            </template>

                            <template v-if="showHelp" #help>
                                Enter the total amount of hours you spend traveling each day for work-related purposes. This includes commuting, traveling
                                to meet clients, or any other work-related travel.
                            </template>
                        </UFormGroup>

                        <UFormGroup label="Time spent doing administrative work per day?" name="dailyAdminSegments">
                            <URange
                                v-model.number="state.dailyAdminSegments"
                                :step="1"
                                :min="0"
                                :max="4 * 60 / segmentSizeInMinutes"
                            />

                            <template #hint>
                                {{ getTaskTime(state.dailyAdminSegments) }} ({{ formatPercentage((state.dailyAdminSegments ?? 0)/totalWorkingSegments) }}%)
                            </template>

                            <template v-if="showHelp" #help>
                                Enter the amount of time you spend each day on administrative tasks, such as
                                scheduling,
                                communicating with clients, collecting payments, or managing other non-billable
                                business
                                tasks
                            </template>
                        </UFormGroup>

                        <UFormGroup label="Time spent travelling per day?" name="dailyTravelSegments">
                            <URange
                                v-model.number="state.dailyTravelSegments"
                                :step="1"
                                :min="0"
                                :max="4 * 60 / segmentSizeInMinutes"
                            />

                            <template #hint>
                                {{ getTaskTime(state.dailyTravelSegments) }} ({{ formatPercentage((state.dailyTravelSegments ?? 0)/totalWorkingSegments) }}%)
                            </template>

                            <template v-if="showHelp" #help>
                                Enter the total amount of hours you spend traveling each day for work-related purposes. This includes commuting, traveling
                                to meet clients, or any other work-related travel.
                            </template>
                        </UFormGroup>

                        <UFormGroup label="Time spent taking breaks/meals per day?" name="dailyBreakSegments">
                            <URange
                                v-model.number="state.dailyBreakSegments"
                                :step="1"
                                :min="0"
                                :max="4 * 60 / segmentSizeInMinutes"
                            />

                            <template #hint>
                                {{ getTaskTime(state.dailyBreakSegments) }} ({{ formatPercentage((state.dailyBreakSegments ?? 0)/totalWorkingSegments) }}%)
                            </template>

                            <template v-if="showHelp" #help>
                                Enter the total time you spend on breaks and meals during your workday.
                            </template>
                        </UFormGroup>

                        <UAlert
                            title="Total time spent working per day"
                            :description="getTaskTime(totalWorkingSegments)"
                            class="col-span-full"
                            color="primary" />
                    </div>
                </template>

                <template v-if="step === 'expenses'">
                    <p>
                        Every business has costs, and understanding yours is key to setting accurate financial goals and
                        pricing your services effectively. This section will help you account for all the expenses
                        involved in running your business—from supplies and equipment to travel and marketing. By
                        identifying these costs, we’ll ensure your earnings are not only sustainable but also
                        profitable.
                    </p>

                    <div class="grid gap-4 grid-cols-1 md:grid-cols-2">
                        <UFormGroup v-if="state.country === 'XX'" label="Mileage rate/unit" name="mileageRate">
                            <URange
                                v-model.number="state.mileageRate"
                                :step="0.01"
                                :min="0"
                                :max="2"
                            />

                            <template #hint>
                                {{ formatCurrency(state.mileageRate ?? 0, 2)}}<template v-if="mileageExpense">/{{ mileageExpense.unit }}</template>
                            </template>

                            <template v-if="showHelp" #help>
                                Enter the reimbursement rate you use for mileage expenses. This rate accounts for the
                                costs
                                of operating a vehicle for work purposes, including fuel, maintenance, wear and tear,
                                insurance, and depreciation. Be sure to use the rate applicable to your country and the
                                unit
                                of measurement (miles or kilometers) you track your travel in.
                            </template>
                        </UFormGroup>

                        <UFormGroup label="Monthly marketing expenses" name="monthlyMarketingExpenses">
                            <URange
                                v-model.number="state.monthlyMarketingExpenses"
                                :step="10"
                                :min="0"
                                :max="1000"
                            />

                            <template #hint>
                                {{ formatCurrency(state.monthlyMarketingExpenses ?? 0) }}/month
                            </template>

                            <template v-if="showHelp" #help>
                                Enter the average amount you spend on marketing your business. This includes expenses
                                for
                                advertisements, promotional materials, social media campaigns, website hosting, and any
                                other efforts to attract new clients or retain existing ones.
                            </template>
                        </UFormGroup>

                        <UFormGroup label="Monthly subscriptions" name="monthlySubscriptions">
                            <URange
                                v-model.number="state.monthlySubscriptions"
                                :step="10"
                                :min="0"
                                :max="1000"
                            />

                            <template #hint>
                                {{ formatCurrency(state.monthlySubscriptions ?? 0) }}/month
                            </template>

                            <template v-if="showHelp" #help>
                                Enter the total amount you spend each month on subscriptions related to your business.
                                This
                                may include software, apps, industry memberships, scheduling tools, or other recurring
                                services that support your operations.
                            </template>
                        </UFormGroup>

                        <div class="grid grid-cols-1 gap-4 lg:grid-cols-2 lg:col-span-full">
                            <UFormGroup label="Monthly Offce/Storage Rent" name="monthlyOfficeSt</div>orageRent">
                                <URange
                                    v-model.number="state.monthlyOfficeStorageRent"
                                    :step="10"
                                    :min="0"
                                    :max="10000"
                                />

                                <template #hint>
                                    {{ formatCurrency(state.monthlyOfficeStorageRent ?? 0) }}/month
                                </template>

                                <template v-if="showHelp" #help>
                                    Enter the total amount you spend each month on office or storage rent for your business.
                                    This may include rent for a dedicated office, warehouse, storage unit, or any other physical space used to support your operations.
                                    Be sure to include any associated fees or costs directly tied to maintaining this space.
                                </template>
                            </UFormGroup>
                        </div>

                        <UFormGroup label="Equipment costs per year" name="equipmentCostsPerYear">
                            <URange
                                v-model.number="state.equipmentCostsPerYear"
                                :step="100"
                                :min="0"
                                :max="5000"
                            />

                            <template #hint>
                                {{ formatCurrency(state.equipmentCostsPerYear ?? 0) }}/year
                            </template>

                            <template v-if="showHelp" #help>
                                Enter the total amount you spend annually on equipment. This includes the purchase,
                                maintenance, and replacement of tools like vacuums, mops, buckets, and other essential
                                items
                                needed for your cleaning services.
                            </template>
                        </UFormGroup>

                        <UFormGroup

                            label="General liability insurance per year"
                            name="generalLiabilityInsurancePerYear">
                            <URange
                                v-model.number="state.generalLiabilityInsurancePerYear"
                                :step="1"
                                :min="0"
                                :max="5000"
                            />

                            <template #hint>
                                {{ formatCurrency(state.generalLiabilityInsurancePerYear ?? 0) }}/year
                            </template>

                            <template v-if="showHelp" #help>
                                Enter the annual cost of your general liability insurance. This covers your business
                                against
                                claims of property damage or personal injury caused during your work, helping to protect
                                your financial stability.
                            </template>
                        </UFormGroup>

                        <UFormGroup label="Bonding per year" name="bondingPerYear">
                            <URange
                                v-model.number="state.bondingPerYear"
                                :step="1"
                                :min="0"
                                :max="5000"
                            />

                            <template #hint>
                                {{ formatCurrency(state.bondingPerYear ?? 0) }}/year
                            </template>

                            <template v-if="showHelp" #help>
                                Enter the total amount you spend annually on bonding fees. Bonding protects your clients
                                against potential theft or fraud and helps build trust in your services.
                            </template>
                        </UFormGroup>

                        <UFormGroup
                            label="Health insurance per year"
                            name="healthInsurancePerYear">
                            <URange
                                v-model.number="state.healthInsurancePerYear"
                                :step="250"
                                :min="0"
                                :max="20000"
                            />

                            <template #hint>
                                {{ formatCurrency(state.healthInsurancePerYear ?? 0) }}/year
                            </template>

                            <template v-if="showHelp" #help>
                                Enter the total amount you spend annually on health insurance. This provides coverage for medical expenses and preventive care,
                                ensuring financial protection against unexpected healthcare costs for you and your business.
                            </template>
                        </UFormGroup>

                        <UFormGroup

                            label="Commercial auto insurance per year"
                            name="commercialAutoInsurancePerYear">
                            <URange
                                v-model.number="state.commercialAutoInsurancePerYear"
                                :step="100"
                                :min="0"
                                :max="5000"
                            />

                            <template #hint>
                                {{ formatCurrency(state.commercialAutoInsurancePerYear ?? 0) }}/year
                            </template>

                            <template v-if="showHelp" #help>
                                Enter the total amount you spend annually on commercial auto insurance. This covers
                                vehicles
                                used for work purposes, protecting against accidents, theft, or damage while ensuring
                                compliance with business insurance requirements.
                            </template>
                        </UFormGroup>

                        <UFormGroup label="Business licensing per year" name="businessLicensingPerYear">
                            <URange
                                v-model.number="state.businessLicensingPerYear"
                                :step="100"
                                :min="0"
                                :max="5000"
                            />

                            <template #hint>
                                {{ formatCurrency(state.businessLicensingPerYear ?? 0) }}/year
                            </template>

                            <template v-if="showHelp" #help>
                                Enter the total amount you spend annually on business licenses, permits, or other
                                regulatory
                                fees required to operate your business legally. This may include local, state, or
                                industry-specific licensing costs.
                            </template>
                        </UFormGroup>

                        <UFormGroup
                            label="Accounting/legal expenses per year"
                            name="accountingLegalExpensesPerYear">
                            <URange
                                v-model.number="state.accountingLegalExpensesPerYear"
                                :step="100"
                                :min="0"
                                :max="5000"
                            />

                            <template #hint>
                                {{ formatCurrency(state.accountingLegalExpensesPerYear ?? 0) }}/year
                            </template>

                            <template v-if="showHelp" #help>
                                Enter the total amount you spend annually on accounting, legal, or other professional
                                services. This includes costs for tax preparation, bookkeeping, legal advice, or
                                consulting
                                services to support your business operations.
                            </template>
                        </UFormGroup>

                        <UFormGroup label="Other expenses per year" name="otherExpensesPerYear">
                            <URange
                                v-model.number="state.otherExpensesPerYear"
                                :step="1"
                                :min="0"
                                :max="5000"
                            />

                            <template #hint>
                                {{ formatCurrency(state.otherExpensesPerYear ?? 0) }}/year
                            </template>

                            <template v-if="showHelp" #help>
                                Enter the total amount you spend annually on any other business-related expenses not
                                already
                                listed. This may include unexpected costs, miscellaneous supplies, or additional
                                services
                                required to run your business.
                            </template>
                        </UFormGroup>
                    </div>
                </template>

                <template v-if="step === 'email'">
                    <p>
                        Enter a valid email address so we can send you your personalized pricing report. Your information will remain private—we will
                        not share or sell it. Please ensure the email is correct, as you won’t receive your report otherwise.
                    </p>

                    <div class="grid gap-4 grid-cols-1 sm:grid-cols-2">
                        <UFormGroup required label="Email Address" name="emailAddress">
                            <UInput v-model="state.emailAddress" type="email" />
                        </UFormGroup>
                    </div>

                </template>

                <template v-if="step === 'business-info'">
                    <p>
                        Congratulations! You’ve completed the calculator and are one step closer to achieving your
                        financial goals. To receive your personalized pricing report, please provide us with some
                        additional information about your business and how you heard about us. We will then email you
                        a link to access your personalized report.
                    </p>

                    <div class="grid gap-4 grid-cols-1 sm:grid-cols-2">
                        <UFormGroup required label="Company Name" name="companyName">
                            <UInput v-model="state.companyName" maxlength="64" />
                        </UFormGroup>

                        <UFormGroup required label="Your Name" name="contactName">
                            <UInput v-model="state.contactName" maxlength="64" />
                        </UFormGroup>

                        <UFormGroup label="How many years in business?" name="yearsInBusiness">
                            <URange
                                v-model.number="state.yearsInBusiness"
                                :step="1"
                                :min="0"
                                :max="20"
                            />

                            <template #hint>
                                {{ state.yearsInBusiness }} years
                            </template>
                        </UFormGroup>

                        <UFormGroup label="How many staff? (including yourself)" name="staffCount">
                            <URange
                                v-model.number="state.staffCount"
                                :step="1"
                                :min="1"
                                :max="10"
                            />

                            <template #hint>
                                {{ state.staffCount }} staff
                            </template>

                        </UFormGroup>


                        <div class="grid grid-cols-1 gap-4 lg:grid-cols-2 lg:col-span-full">
                            <UFormGroup
                                label="Do you currently use business management software?"
                                name="usesBusinessManagementSoftware"
                            >
                                <USelect v-model="state.usesBusinessManagementSoftware" :options="yesNoOptions" />

                                <template v-if="showHelp" #help>
                                    Let us know if you’re using any tools or systems to manage your business operations.
                                    This could include software for scheduling, invoicing, tracking clients, or organizing
                                    your day-to-day tasks.
                                </template>
                            </UFormGroup>

                            <UFormGroup
                                v-if="state.usesBusinessManagementSoftware === '1'"
                                label="Which business management software?"
                                name="businessManagementSoftware"
                                maxlength="512"
                            >
                                <UInput v-model="state.businessManagementSoftware" />

                                <template v-if="showHelp" #help>
                                    Let us know which business management software you currently use. This could include any tools or platforms that
                                    help you manage scheduling, invoicing, client tracking, or other day-to-day operations.
                                </template>
                            </UFormGroup>
                        </div>

                        <DataGroup
                            label="What are your primary business challenges? (pick one or more)"
                            name="businessChallenges"
                            class="sm:col-span-full">
                            <div class="grid gap-1 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3">
                                <UCheckbox
                                    v-for="option in businessChallengeOptions"
                                    :key="option.value"
                                    v-model="state.businessChallenges"
                                    :label="option.label"
                                    :value="option.value" />
                            </div>
                        </DataGroup>

                        <UFormGroup required label="How did you learn about us?" name="marketingChannel">
                            <USelect v-model="state.marketingChannel" :options="marketingChannelOptions" />

                            <template v-if="showHelp" #help>
                                Let us know how you heard about us! Your answer helps us understand what’s working and
                                improve how we connect with businesses like yours. Whether it’s through a search engine,
                                social media, or word of mouth, your feedback is invaluable.
                            </template>
                        </UFormGroup>

                        <UFormGroup label="Phone Number (Optional)" name="phoneNumber">
                            <UInput v-model="state.phoneNumber" type="tel" />

                            <template v-if="showHelp" #help>
                                Enter a valid phone number so we can send you updates or reach out if needed. Your information will remain private—we will
                                not share or sell it. We may follow up to see how Augwa can assist you better.
                            </template>
                        </UFormGroup>

                        <UFormGroup label="Is there anything else you'd like to share? (Optional)" name="additionalComments" class="col-span-full">
                            <UTextarea v-model="state.additionalComments" />

                            <template v-if="showHelp" #help>
                                Use this section to share any additional details, questions, or specific needs about your business. We’d also love to hear your feedback
                                on this experience to help us improve and better serve you.
                            </template>
                        </UFormGroup>
                    </div>
                </template>

                <template v-if="step === 'finished'">
                    <p>
                        We’ve received your details and are preparing your personalized pricing report. You’ll soon
                        receive an email with a link to access your report. Be sure to check your inbox (and spam
                        folder, just in case).
                    </p>
                    <p>
                        We’re excited to help you take the next step toward optimizing your business — stay tuned!
                    </p>

                </template>

                <div class="flex flex-col sm:flex-row-reverse gap-4 justify-between sm:justify-start pt-4">
                    <UButton
                        v-if="nextButtonText"
                        type="submit"
                        color="secondary"
                        size="xl"
                        class="w-full flex justify-center items-center sm:w-fit"
                        :disabled="!loaded || submitting">
                        {{ nextButtonText }}
                    </UButton>

                    <UButton
                        v-if="prevButtonText"
                        color="white"
                        size="xl"
                        :disabled="submitting || submitHasErrors"
                        class="w-full flex justify-center items-center sm:w-fit"
                        @click="handlePrev">
                        {{ prevButtonText }}
                    </UButton>
                </div>

                <p v-if="['required-earnings', 'target-earnings'].includes(step)" class="caption text-gray-900 italic">
                    Disclaimer: The tax calculations provided by this tool are for informational purposes only and should not be considered as legal,
                    financial, or tax advice. While we strive for accuracy, tax laws and rates may change, and individual circumstances can vary. We
                    recommend consulting a licensed tax professional or referring to official government resources to ensure compliance with applicable
                    tax laws. We do not accept any liability for errors, omissions, or any outcomes resulting from the use of this calculator.
                </p>
            </div>
        </UForm>

        <DotLottieVue
            ref="celebrate"
            class="w-screen h-screen absolute bottom-0 left-0 overflow-hidden z-50 pointer-events-none"
            src="https://lottie.host/2cf96257-bcce-4278-9025-fce3a7ed8c48/rWfMYKdlKV.lottie"
            preserveAspectRatio="xMidYMid slice" />

        <LazyUNotifications />
    </div>
</template>

<script lang="ts" setup>
import type { InferOutput } from 'valibot'
import type { FormSubmitEvent } from '#ui/types'
import { DotLottieVue } from '@lottiefiles/dotlottie-vue'
import type { MarketingChannel, ProgressiveLead } from '~/types/api'
import type { CountryOption, Option } from '~/types'
import countryOptions from '~/lib/extended-countries'
import regionOptions from '~/lib/regions'
import marketingChannelOptions from '~/lib/marketing-channels'
import businessChallengeOptions from '~/lib/business-challenges'
import yesNoOptions from '~/lib/yes-no'

type Steps = 'intro' | 'location' | 'required-earnings' | 'target-earnings' | 'time-off' | 'work-schedule' | 'expenses' | 'email' | 'business-info' | 'finished'

definePageMeta({
    layout: 'calculator',
})

const handleSubmit = async ({ data }: FormSubmitEvent<FormSchema>) => {
    try {
        submitting.value = true
        if (stepNumber.value === 0) {
            progressiveId.value = await claimProgressiveId()
        } else {
            await updateProgressiveLead(data)
        }

        submitHasErrors.value = false
    } catch (error) {
        submitHasErrors.value = true
        captureError(error)

        toast.add({
            title: 'Failed to save progress',
            description: `Review your responses for any errors and then try again.\n\n${error?.message || error?.title}`,
            timeout: 10000,
            icon: 'i-heroicons:exclamation-triangle-solid',
            // @ts-expect-error custom colors are not in the types
            color: 'danger',
        })

        return
    } finally {
        submitting.value = false
    }

    window.scrollTo(0, 0)

    step.value = steps.at(getStepNumber(step.value)+1)?.step ?? 'intro'
    maxStep.value = step.value

    if (step.value === 'required-earnings') {
        if (data.requiredNetEarnings == null) {
            state.requiredNetEarnings = 3000
            handleRequiredNetEarningsBlur()
        }
    }

    // target earnings
    if (step.value === 'target-earnings' && !data.targetNetEarnings && data.requiredNetEarnings) {
        state.targetNetEarnings = Math.round(data.requiredNetEarnings * 1.1)
        handleTargetNetEarningsBlur()
    }

    // time off
    if (step.value === 'time-off') {
        if (data.holidaysPerYear == null) {
            officalHolidaysPerYear.value = publicHolidaysCount(data.country ?? 'XX', data.region ?? 'XX') ?? 0
            state.holidaysPerYear = officalHolidaysPerYear.value
        }
        if (data.vacationDays == null) state.vacationDays = 10
        if (data.personalDays == null) state.personalDays = 5
    }

    // work schedule
    if (step.value === 'work-schedule') {
        if (data.dailyTravelSegments == null) state.dailyTravelSegments = parseInt(Math.round(60/segmentSizeInMinutes).toFixed(0))
        if (data.dailyBreakSegments == null) state.dailyBreakSegments = parseInt(Math.round(60/segmentSizeInMinutes).toFixed(0))
        if (data.dailyAdminSegments == null) state.dailyAdminSegments = parseInt(Math.round(60/segmentSizeInMinutes).toFixed(0))
        if (data.dailyCleaningSegments == null) state.dailyCleaningSegments =  parseInt(Math.round(6*60/segmentSizeInMinutes).toFixed(0))
        if (data.workDaysPerWeek == null) state.workDaysPerWeek = 5
    }

    // expenses
    if (step.value === 'expenses') {
        if (data.mileageRate == null && mileageExpense.value != null) state.mileageRate = mileageExpense.value.average_rate
        if (data.monthlyMarketingExpenses == null) state.monthlyMarketingExpenses = 100
        if (data.monthlySubscriptions == null) state.monthlySubscriptions = 100
        if (data.monthlyOfficeStorageRent == null) state.monthlyOfficeStorageRent = 0
        if (data.equipmentCostsPerYear == null) state.equipmentCostsPerYear = 900
        if (data.generalLiabilityInsurancePerYear == null) state.generalLiabilityInsurancePerYear = 1000
        if (data.bondingPerYear == null) state.bondingPerYear = 500
        if (data.healthInsurancePerYear == null) state.healthInsurancePerYear = 3000
        if (data.commercialAutoInsurancePerYear == null) state.commercialAutoInsurancePerYear = 1000
        if (data.businessLicensingPerYear == null) state.businessLicensingPerYear = 100
        if (data.accountingLegalExpensesPerYear == null) state.accountingLegalExpensesPerYear = 1000
        if (data.otherExpensesPerYear == null) state.otherExpensesPerYear = 1000
    }

    // business information
    if (step.value === 'business-info') {
        if (data.yearsInBusiness == null) state.yearsInBusiness = 0
        if (data.staffCount == null) state.staffCount = 1
        if (data.businessChallenges == null) state.businessChallenges = []
        if (data.phoneNumber == null) state.phoneNumber = ''
        if (data.additionalComments == null) state.additionalComments = ''
    }

    setTimeout(() => {
        const firstFormElement = document.querySelector('form input:not([type="hidden"]):not([disabled]), form select:not([disabled]), form textarea:not([disabled]), div[id^="headlessui-listbox-button"] > button') as HTMLElement
        if (firstFormElement) {
            firstFormElement.focus()
        }
    })

    if (step.value === 'finished') {
        await finializedProgressiveLead()
        setTimeout(() => celebrate.value?.getDotLottieInstance().play(), 500)
    }

}

const claimProgressiveId = async () => {
    const { data, error } = await apiClient.POST('/Lead/{source}/Start', {
        params: {
            path: {
                source: 'PricingReport',
            },
        },
    })

    if (error) throw error
    if (data.id == null) throw new Error('No ID returned from API')

    return data.id
}

const updateProgressiveLead = async (data: FormSchema) => {
    const payload: ProgressiveLead = {
        ...data,
        usesBusinessManagementSoftware: data.usesBusinessManagementSoftware === '1',
        step: stepName.value,
        marketingChannel: data.marketingChannel as MarketingChannel,
    }
    if (data.requiredNetEarnings != null) payload.requiredNetEarnings = data.requiredNetEarnings * 12
    if (data.targetNetEarnings != null) payload.targetNetEarnings = data.targetNetEarnings * 12
    if (data.requiredTaxRate != null) payload.requiredTaxRate = parseFloat((data.requiredTaxRate / 100).toFixed(4))
    if (data.targetTaxRate != null) payload.targetTaxRate = parseFloat((data.targetTaxRate / 100).toFixed(4))
    if (data.dailyCleaningSegments != null) payload.dailyCleaningMinutes = data.dailyCleaningSegments * segmentSizeInMinutes
    if (data.dailyAdminSegments != null) payload.dailyAdminMinutes = data.dailyAdminSegments * segmentSizeInMinutes
    if (data.dailyTravelSegments != null) payload.dailyTravelMinutes = data.dailyTravelSegments * segmentSizeInMinutes
    if (data.dailyBreakSegments != null) payload.dailyBreakMinutes = data.dailyBreakSegments * segmentSizeInMinutes
    if (payload.dailyTravelMinutes != null) payload.dailyMileage = parseInt((payload.dailyTravelMinutes * (mileageExpense.value?.unit === 'mi' ? 0.416666667 : 0.666666667)).toFixed(0))
    if (payload.dailyCleaningMinutes != null) payload.dailyCleaningSuppliesExpense = parseInt(Math.ceil(payload.dailyCleaningMinutes / 60).toFixed(0))
    payload.expectedScheduleAvailability = 0.30
    payload.targetScheduleAvailability = 0.10

    const { error } = await apiClient.PATCH(`/Lead/{progressiveId}/Resume`, {
        body: payload,
        params: {
            path: {
                progressiveId: progressiveId.value,
            },
        },
    })

    if (error) throw error
}

const finializedProgressiveLead = async () => {
    const { error } = await apiClient.POST(`/Lead/{progressiveId}/Submit`, {
        params: {
            path: {
                progressiveId: progressiveId.value,
            },
        },
    })

    if (error) throw error
}

const handlePrev = () => {
    window.scrollTo(0, 0)
    step.value = steps.at(stepNumber.value-1)?.step ?? 'intro'
}

const handleRequiredNetEarningsBlur = () => {
    if (state.requiredNetEarnings) {
        state.requiredGrossEarnings = Number((calculateGrossEarnings(state.requiredNetEarnings * 12, state.country ?? 'XX', state.region ?? 'XX')).toFixed(0))
        state.requiredTaxRate = parseFloat(((1 - (state.requiredNetEarnings / (state.requiredGrossEarnings/12))) * 100).toFixed(2))
    }
}

const handleRequiredGrossEarningsBlur = () => {
    if (state.requiredGrossEarnings) {
        state.requiredNetEarnings = Number((calculateNetEarnings(state.requiredGrossEarnings, state.country ?? 'XX', state.region ?? 'XX') / 12).toFixed(0))
        state.requiredTaxRate = parseFloat(((1 - (state.requiredNetEarnings / (state.requiredGrossEarnings/12))) * 100).toFixed(2))
    }
}

const handleRequiredTaxRateBlur = () => {
    if (state.requiredGrossEarnings) {
        state.requiredNetEarnings = Number((state.requiredGrossEarnings/12 * (1 - ((state.requiredTaxRate ?? 0) / 100))).toFixed(0))
    }
}

const handleTargetNetEarningsBlur = () => {
    if (state.targetNetEarnings) {
        state.targetGrossEarnings = Number((calculateGrossEarnings(state.targetNetEarnings * 12, state.country ?? 'XX', state.region ?? 'XX')).toFixed(0))
        state.targetTaxRate = parseFloat(((1 - (state.targetNetEarnings / (state.targetGrossEarnings/12))) * 100).toFixed(2))
    }
}

const handleTargetGrossEarningsBlur = () => {
    if (state.targetGrossEarnings) {
        state.targetNetEarnings = Number((calculateNetEarnings(state.targetGrossEarnings, state.country ?? 'XX', state.region ?? 'XX') / 12).toFixed(0))
        state.targetTaxRate = parseFloat(((1 - (state.targetNetEarnings / (state.targetGrossEarnings/12))) * 100).toFixed(2))
    }
}

const handleTargetTaxRateBlur = () => {
    if (state.targetGrossEarnings) {
        state.targetNetEarnings = Number((state.targetGrossEarnings/12 * (1 - ((state.targetTaxRate ?? 0) / 100))).toFixed(0))
    }
}

const getStepNumber = (step: Steps) => steps.findIndex(s => s.step === step)
const isFutureStep = (step: Steps) => getStepNumber(step) > stepNumber.value
const getTaskTime = (segment: number = 0) => humanizeTime(segment * segmentSizeInMinutes * 60)

const segmentSizeInMinutes = 15

const steps: { step: Steps, title: string }[] = [
    { step: 'intro', title: 'Cleaning Services Pricing Calculator: Find the Right Rate for Your Business'},
    { step: 'location', title: 'Where do you operate from?'},
    { step: 'required-earnings', title: 'How much do I need to make?'},
    { step: 'target-earnings', title: 'How much would I like to make?'},
    { step: 'time-off', title: 'How much time off will I take?'},
    { step: 'work-schedule', title: 'How does my work week look?'},
    { step: 'expenses', title: 'What does it cost to run my business?'},
    { step: 'email', title: 'Where should we send your report?'},
    { step: 'business-info', title: 'Tell us about your business'},
    { step: 'finished', title: 'Confirmation!'},
]
const totalSteps = steps.length-1

const v = useValibot()
const toast = useToast()
const apiClient = useApiClient()

const formatCurrency = computed(() => useFormatCurrency(selectedCountry.value?.value))
const formatPercentage = computed(() => useFormatPercentage(selectedCountry.value?.value))

const formSchema = v.object({
    country: v.union([
        v.custom<string | undefined>(() => isFutureStep('location')),
        v.pipe(
            v.string(),
            v.trim(),
            v.minLength(1, 'required')
        ),
    ], 'Required'),
    region: v.union([
        v.custom<string | undefined>(() => isFutureStep('location')),
        v.custom<string>(() => selectedCountry.value?.value === 'XX'),
        v.pipe(
            v.string(),
            v.trim(),
            v.minLength(1, 'required')
        ),
    ], 'Required'),
    city: v.union([
        v.custom<string | undefined>(() => isFutureStep('location')),
        v.pipe(
            v.string(),
            v.trim(),
            v.maxLength(64),
            v.minLength(1, 'required')
        ),
    ], 'Required'),

    requiredGrossEarnings: v.union([
        v.custom<number | undefined>(() => isFutureStep('required-earnings')),
        v.pipe(
            v.number(),
            v.minValue(1, 'required')
        ),
    ], 'Required'),
    requiredNetEarnings: v.union([
        v.custom<number | undefined>(() => isFutureStep('required-earnings')),
        v.pipe(
            v.number(),
            v.minValue(1)
        ),
    ], 'Required'),
    requiredTaxRate: v.union([
        v.custom<number | undefined>(() => isFutureStep('required-earnings')),
        v.pipe(
            v.number(),
            v.minValue(0, 'required'),
            v.maxValue(100)
        ),
    ], 'required'),

    targetGrossEarnings: v.union([
        v.custom<number | undefined>(() => isFutureStep('target-earnings')),
        v.pipe(
            v.number(),
            v.minValue(1, 'required')
        ),
    ], 'Required'),
    targetNetEarnings: v.union([
        v.custom<number | undefined>(() => isFutureStep('target-earnings')),
        v.pipe(
            v.number(),
            v.minValue(1)
        ),
    ], 'Required'),
    targetTaxRate: v.union([
        v.custom<number | undefined>(() => isFutureStep('target-earnings')),
        v.pipe(
            v.number(),
            v.minValue(0),
            v.maxValue(100)
        ),
    ], 'Required'),

    vacationDays: v.union([
        v.custom<number | undefined>(() => isFutureStep('time-off')),
        v.pipe(
            v.number(),
            v.minValue(0, 'required')
        ),
    ], 'Required'),
    holidaysPerYear: v.union([
        v.custom<number | undefined>(() => isFutureStep('time-off')),
        v.pipe(
            v.number(),
            v.minValue(0, 'required')
        ),
    ], 'Required'),
    personalDays: v.union([
        v.custom<number | undefined>(() => isFutureStep('time-off')),
        v.pipe(
            v.number(),
            v.minValue(0, 'required')
        ),
    ], 'Required'),

    dailyTravelSegments: v.union([
        v.custom<number | undefined>(() => isFutureStep('work-schedule')),
        v.pipe(
            v.number(),
            v.minValue(0, 'required')
        ),
    ], `Required`),
    dailyBreakSegments: v.union([
        v.custom<number | undefined>(() => isFutureStep('work-schedule')),
        v.pipe(
            v.number(),
            v.minValue(0, 'required')
        ),
    ], 'Required'),
    dailyAdminSegments: v.union([
        v.custom<number | undefined>(() => isFutureStep('work-schedule')),
        v.pipe(
            v.number(),
            v.minValue(0, 'required')
        ),
    ], 'Required'),
    dailyCleaningSegments: v.union([
        v.custom<number | undefined>(() => isFutureStep('work-schedule')),
        v.pipe(
            v.number(),
            v.minValue(0, 'required')
        ),
    ], 'Required'),
    workDaysPerWeek: v.union([
        v.custom<number | undefined>(() => isFutureStep('work-schedule')),
        v.pipe(
            v.number(),
            v.minValue(1, 'required'),
            v.maxValue(7)
        ),
    ], 'Required'),

    mileageRate: v.union([
        v.custom<number | undefined>(() => isFutureStep('expenses')),
        v.pipe(
            v.number(),
            v.minValue(0, 'required')
        ),
    ], 'Required'),
    monthlyMarketingExpenses: v.union([
        v.custom<number | undefined>(() => isFutureStep('expenses')),
        v.pipe(
            v.number(),
            v.minValue(0, 'required')
        ),
    ], 'Required'),
    monthlySubscriptions: v.union([
        v.custom<number | undefined>(() => isFutureStep('expenses')),
        v.pipe(
            v.number(),
            v.minValue(0, 'required')
        ),
    ], 'Required'),
    monthlyOfficeStorageRent: v.union([
        v.custom<number | undefined>(() => isFutureStep('expenses')),
        v.pipe(
            v.number(),
            v.minValue(0, 'required')
        ),
    ], 'Required'),
    equipmentCostsPerYear: v.union([
        v.custom<number | undefined>(() => isFutureStep('expenses')),
        v.pipe(
            v.number(),
            v.minValue(0, 'required')
        ),
    ], 'Required'),
    generalLiabilityInsurancePerYear: v.union([
        v.custom<number | undefined>(() => isFutureStep('expenses')),
        v.pipe(
            v.number(),
            v.minValue(0, 'required')
        ),
    ], 'Required'),
    bondingPerYear: v.union([
        v.custom<number | undefined>(() => isFutureStep('expenses')),
        v.pipe(
            v.number(),
            v.minValue(0, 'required')
        ),
    ], 'Required'),
    healthInsurancePerYear: v.union([
        v.custom<number | undefined>(() => isFutureStep('expenses')),
        v.pipe(
            v.number(),
            v.minValue(0, 'required')
        ),
    ], 'Required'),
    commercialAutoInsurancePerYear: v.union([
        v.custom<number | undefined>(() => isFutureStep('expenses')),
        v.pipe(
            v.number(),
            v.minValue(0, 'required')
        ),
    ], 'Required'),
    businessLicensingPerYear: v.union([
        v.custom<number | undefined>(() => isFutureStep('expenses')),
        v.pipe(
            v.number(),
            v.minValue(0, 'required')
        ),
    ], 'Required'),
    accountingLegalExpensesPerYear: v.union([
        v.custom<number | undefined>(() => isFutureStep('expenses')),
        v.pipe(
            v.number(),
            v.minValue(0, 'required')
        ),
    ], 'Required'),
    otherExpensesPerYear: v.union([
        v.custom<number | undefined>(() => isFutureStep('expenses')),
        v.pipe(
            v.number(),
            v.minValue(0, 'required')
        ),
    ], 'Required'),

    companyName: v.union([
        v.custom<string | undefined>(() => isFutureStep('business-info')),
        v.pipe(
            v.string(),
            v.trim(),
            v.minLength(1, 'required'),
            v.maxLength(64)
        ),
    ], 'Required'),
    contactName: v.union([
        v.custom<string | undefined>(() => isFutureStep('business-info')),
        v.pipe(
            v.string(),
            v.trim(),
            v.minLength(1, 'required'),
            v.maxLength(64)
        ),
    ], 'Required'),
    yearsInBusiness: v.union([
        v.custom<number | undefined>(() => isFutureStep('business-info')),
        v.pipe(
            v.number(),
            v.minValue(0, 'required')
        ),
    ], 'Required'),
    staffCount: v.union([
        v.custom<number | undefined>(() => isFutureStep('business-info')),
        v.pipe(
            v.number(),
            v.minValue(1, 'required')
        ),
    ], 'Required'),
    usesBusinessManagementSoftware: v.union([
        v.custom<string | undefined>(() => isFutureStep('business-info')),
        v.pipe(
            v.string(),
            v.minLength(1, 'required')
        ),
    ], 'Required'),
    businessManagementSoftware: v.union([
        v.custom<string | undefined>((): boolean => Boolean(isFutureStep('business-info') || state.usesBusinessManagementSoftware === '0')),
        v.pipe(
            v.string(),
            v.trim(),
            v.minLength(1, 'required'),
            v.maxLength(512)
        ),
    ], 'Required'),
    businessChallenges: v.union([
        v.custom<string[] | undefined>(() => isFutureStep('business-info')),
        v.pipe(
            v.array(v.string()),
            v.minLength(1, 'Pick one or more challenges')
        ),
    ], 'Required'),
    marketingChannel: v.union([
        v.custom<string | undefined>(() => isFutureStep('business-info')),
        v.pipe(
            v.string(),
            v.trim(),
            v.minLength(1, 'required')
        ),
    ], 'Required'),
    emailAddress: v.union([
        v.custom<string | undefined>(() => isFutureStep('email')),
        v.pipe(
            v.string(),
            v.trim(),
            v.email(),
            v.minLength(1, 'required'),
            v.maxLength(128)
        ),
    ], 'Required'),
    phoneNumber: v.union([
        v.custom<string | undefined>(() => isFutureStep('business-info')),
        v.pipe(
            v.string(),
            v.trim(),
            v.maxLength(32)
        ),
    ]),
    additionalComments: v.union([
        v.custom<string | undefined>(() => isFutureStep('business-info')),
        v.pipe(
            v.string(),
            v.trim(),
            v.maxLength(4000)
        ),
    ]),
})

type FormSchema = InferOutput<typeof formSchema>

const state = reactive<FormSchema>({
    country: undefined,
    region: undefined,
    city: undefined,
    requiredGrossEarnings: undefined,
    requiredNetEarnings: undefined,
    requiredTaxRate: undefined,
    targetGrossEarnings: undefined,
    targetNetEarnings: undefined,
    targetTaxRate: undefined,
    vacationDays: undefined,
    holidaysPerYear: undefined,
    personalDays: undefined,
    dailyCleaningSegments: undefined,
    dailyTravelSegments: undefined,
    dailyBreakSegments: undefined,
    dailyAdminSegments: undefined,
    workDaysPerWeek: undefined,
    mileageRate: undefined,
    monthlyMarketingExpenses: undefined,
    monthlySubscriptions: undefined,
    monthlyOfficeStorageRent: undefined,
    equipmentCostsPerYear: undefined,
    generalLiabilityInsurancePerYear: undefined,
    bondingPerYear: undefined,
    healthInsurancePerYear: undefined,
    commercialAutoInsurancePerYear: undefined,
    businessLicensingPerYear: undefined,
    accountingLegalExpensesPerYear: undefined,
    otherExpensesPerYear: undefined,
    companyName: undefined,
    contactName: undefined,
    yearsInBusiness: undefined,
    staffCount: undefined,
    usesBusinessManagementSoftware: undefined,
    businessManagementSoftware: undefined,
    businessChallenges: undefined,
    marketingChannel: undefined,
    emailAddress: undefined,
    phoneNumber: undefined,
    additionalComments: undefined,
})

const selectedCountry = ref<CountryOption>()
const selectedRegion = ref<Option<string>>()
const step = ref<Steps>('intro')
const maxStep = ref<Steps>('intro')
const officalHolidaysPerYear = ref<number>()
const loaded = ref(false)
const celebrate = ref<typeof DotLottieVue>()
const progressiveId = ref<string>('')
const submitting = ref(false)
const submitHasErrors = ref(false)
const showHelp = ref(false)

const totalWorkingSegments = computed(() => (state.dailyCleaningSegments ?? 0) + (state.dailyAdminSegments ?? 0) + (state.dailyTravelSegments ?? 0) + (state.dailyBreakSegments ?? 0))

const stepName = computed(() => {
    const s = Math.max(stepNumber.value, getStepNumber(maxStep.value))
    return `${s}/${steps[s]!.step}`
})

const title = computed(() => steps.at(stepNumber.value)?.title)
const stepNumber = computed(() => getStepNumber(step.value))

const prevButtonText = computed(() => {
    if (stepNumber.value <= 1 || stepNumber.value === totalSteps) return null
    return 'Back'
})

const nextButtonText = computed(() => {
    if (stepNumber.value === 0) return 'Get Started'
    if (stepNumber.value === totalSteps - 1) return 'Get My Results'
    if (stepNumber.value === totalSteps) return null
    return 'Next'
})

const regionType = computed(() => {
    if (state.country === 'US') return 'State'
    if (state.country === 'CA') return 'Province'
    if (state.country === 'AU') return 'State'
    if (state.country === 'IE') return 'Province'
    if (state.country === 'NZ') return 'Region'
    if (state.country === 'GB') return 'Region'
    return 'Region'
})

const mileageExpense = computed(() => state.country ? getMileageExpense(state.country) : null)

useHead({
    title: 'Cleaning Services Pricing Calculator: Find the Right Rate for Your Business | Augwa',
    meta: [
        {
            name: "description",
            content: "Discover the ultimate cleaning services pricing calculator designed to help residential cleaners and business owners set competitive, profitable rates. Tailor your pricing based on location, expenses, income goals, and work-life balance. Perfect for understanding your true costs and achieving sustainable success.",
        },
        {
            name: "viewport",
            content: "width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0",
        },
    ],
})

onMounted(() => {
    loaded.value = true
})

watch(selectedCountry, (c) => {
    state.country = c?.value ?? ''
    selectedRegion.value = undefined
})

watch(selectedRegion, (r) => {
    state.region = r?.value ?? ''
})
</script>