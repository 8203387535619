<template>
    <div>
        <div
            v-if="label || $slots.label"
            class="flex content-center items-center justify-between text-sm"
            :class="size">
            <label class="block font-medium text-primary-700 dark:text-gray-200">
                <slot v-if="$slots.label" name="label" />
                <template v-else>{{ label }}</template>
            </label>
        </div>
        <div class="mt-1 relative">
            <slot v-bind="{ error }" />

            <p v-if="typeof error === 'string' && error" :class="['mt-2 text-red-500 dark:text-red-400', size]">
                <slot v-if="$slots.error" name="error" v-bind="{ error, label, name, help }" />
                <template v-else>
                    {{ error }}
                </template>
            </p>

            <p v-else-if="help || $slots.help" :class="['mt-2 text-gray-500 dark:text-gray-400', size]">
                <slot v-if="$slots.help" name="help" v-bind="{ error, label, name, help }" />
                <template v-else>
                    {{ help }}
                </template>
            </p>
        </div>
    </div>
</template>

<script setup lang="ts">
import type { FormError, InjectedFormGroupValue, FormGroupSize } from '#ui/types'

const props = withDefaults(defineProps<{
    name: string
    label?: string
    help?: string
    error?: string | boolean
    eagerValidation?: boolean
    size?: FormGroupSize
}>(), {
    size: 'md',
    error: undefined,
})

const formErrors = inject<Ref<FormError[]> | null>('form-errors', null)

const error = computed(() => {
    return (props.error && typeof props.error === 'string') || typeof props.error === 'boolean'
        ? props.error
        : formErrors?.value?.find(error => error.path === props.name)?.message
})

provide<InjectedFormGroupValue>('form-group', {
    error,
    inputId: ref<string>(),
    name: computed(() => props.name),
    size: computed(() => props.size),
    eagerValidation: computed(() => props.eagerValidation),
})
</script>