import type { Option } from '~/types'

export default {
    "US": [
        { label: "Alabama", value: "AL" },
        { label: "Alaska", value: "AK" },
        { label: "Arizona", value: "AZ" },
        { label: "Arkansas", value: "AR" },
        { label: "California", value: "CA" },
        { label: "Colorado", value: "CO" },
        { label: "Connecticut", value: "CT" },
        { label: "Delaware", value: "DE" },
        { label: "Florida", value: "FL" },
        { label: "Georgia", value: "GA" },
        { label: "Hawaii", value: "HI" },
        { label: "Idaho", value: "ID" },
        { label: "Illinois", value: "IL" },
        { label: "Indiana", value: "IN" },
        { label: "Iowa", value: "IA" },
        { label: "Kansas", value: "KS" },
        { label: "Kentucky", value: "KY" },
        { label: "Louisiana", value: "LA" },
        { label: "Maine", value: "ME" },
        { label: "Maryland", value: "MD" },
        { label: "Massachusetts", value: "MA" },
        { label: "Michigan", value: "MI" },
        { label: "Minnesota", value: "MN" },
        { label: "Mississippi", value: "MS" },
        { label: "Missouri", value: "MO" },
        { label: "Montana", value: "MT" },
        { label: "Nebraska", value: "NE" },
        { label: "Nevada", value: "NV" },
        { label: "New Hampshire", value: "NH" },
        { label: "New Jersey", value: "NJ" },
        { label: "New Mexico", value: "NM" },
        { label: "New York", value: "NY" },
        { label: "North Carolina", value: "NC" },
        { label: "North Dakota", value: "ND" },
        { label: "Ohio", value: "OH" },
        { label: "Oklahoma", value: "OK" },
        { label: "Oregon", value: "OR" },
        { label: "Pennsylvania", value: "PA" },
        { label: "Rhode Island", value: "RI" },
        { label: "South Carolina", value: "SC" },
        { label: "South Dakota", value: "SD" },
        { label: "Tennessee", value: "TN" },
        { label: "Texas", value: "TX" },
        { label: "Utah", value: "UT" },
        { label: "Vermont", value: "VT" },
        { label: "Virginia", value: "VA" },
        { label: "Washington", value: "WA" },
        { label: "West Virginia", value: "WV" },
        { label: "Wisconsin", value: "WI" },
        { label: "Wyoming", value: "WY" },
        { label: "Puerto Rico", value: "PR" },
        { label: "Guam", value: "GU" },
        { label: "American Samoa", value: "AS" },
        { label: "U.S. Virgin Islands", value: "VI" },
        { label: "Northern Mariana Islands", value: "MP" },
    ],
    "CA": [
        { label: "Alberta", value: "AB" },
        { label: "British Columbia", value: "BC" },
        { label: "Manitoba", value: "MB" },
        { label: "New Brunswick", value: "NB" },
        { label: "Newfoundland and Labrador", value: "NL" },
        { label: "Nova Scotia", value: "NS" },
        { label: "Ontario", value: "ON" },
        { label: "Prince Edward Island", value: "PE" },
        { label: "Quebec", value: "QC" },
        { label: "Saskatchewan", value: "SK" },
        { label: "Northwest Territories", value: "NT" },
        { label: "Nunavut", value: "NU" },
        { label: "Yukon", value: "YT" },
    ],
    "AU": [
        { label: "New South Wales", value: "NSW" },
        { label: "Victoria", value: "VIC" },
        { label: "Queensland", value: "QLD" },
        { label: "South Australia", value: "SA" },
        { label: "Western Australia", value: "WA" },
        { label: "Tasmania", value: "TAS" },
        { label: "Northern Territory", value: "NT" },
        { label: "Australian Capital Territory", value: "ACT" },
    ],
    "IE": [
        { label: "Leinster", value: "LE" },
        { label: "Munster", value: "MU" },
        { label: "Connacht", value: "CO" },
        { label: "Ulster", value: "UL" },
    ],
    "NZ": [
        { label: "Auckland", value: "AUK" },
        { label: "Wellington", value: "WGN" },
        { label: "Canterbury", value: "CAN" },
        { label: "Waikato", value: "WKO" },
        { label: "Bay of Plenty", value: "BOP" },
        { label: "Otago", value: "OTA" },
        { label: "Manawatu-Wanganui", value: "MWT" },
        { label: "Hawke's Bay", value: "HKB" },
        { label: "Taranaki", value: "TKI" },
        { label: "Southland", value: "STL" },
        { label: "Gisborne", value: "GIS" },
        { label: "Nelson", value: "NSN" },
        { label: "Marlborough", value: "MBH" },
        { label: "West Coast", value: "WTC" },
    ],
    "GB": [
        { label: "England", value: "ENG" },
        { label: "Scotland", value: "SCT" },
        { label: "Wales", value: "WLS" },
        { label: "Northern Ireland", value: "NIR" },
    ],
} as { [key: string]: Option<string>[] }