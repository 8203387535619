import type { TaxBracket } from '~/types/tax'

interface TaxInfo {
    federal: TaxBracket[];
    state: { [state: string]: TaxBracket[] };
    socialSecurity: {
        rate: number;
        maxEarnings: number;
    };
    medicare: {
        rate: number;
        additionalRate: number;
        additionalThreshold: number;
    };
}

const taxInfo: TaxInfo = {
    federal: [
        { rate: 0.10, threshold: 11000 },
        { rate: 0.12, threshold: 44725 },
        { rate: 0.22, threshold: 95375 },
        { rate: 0.24, threshold: 182100 },
        { rate: 0.32, threshold: 231250 },
        { rate: 0.35, threshold: 578125 },
        { rate: 0.37, threshold: Infinity },
    ],
    state: {
        AL: [
            { rate: 0.02, threshold: 500 },
            { rate: 0.04, threshold: 3000 },
            { rate: 0.05, threshold: Infinity },
        ],
        AK: [],
        AZ: [
            { rate: 0.025, threshold: Infinity },
        ],
        AR: [
            { rate: 0.02, threshold: 4499 },
            { rate: 0.04, threshold: 8899 },
            { rate: 0.059, threshold: 13399 },
            { rate: 0.069, threshold: 22299 },
            { rate: 0.079, threshold: Infinity },
        ],
        CA: [
            { rate: 0.01, threshold: 10099 },
            { rate: 0.02, threshold: 23942 },
            { rate: 0.04, threshold: 37788 },
            { rate: 0.06, threshold: 52455 },
            { rate: 0.08, threshold: 66295 },
            { rate: 0.093, threshold: 338639 },
            { rate: 0.103, threshold: 406364 },
            { rate: 0.113, threshold: 677275 },
            { rate: 0.123, threshold: Infinity },
        ],
        CO: [
            { rate: 0.0455, threshold: Infinity },
        ],
        CT: [
            { rate: 0.03, threshold: 10000 },
            { rate: 0.05, threshold: 50000 },
            { rate: 0.055, threshold: 100000 },
            { rate: 0.06, threshold: 200000 },
            { rate: 0.065, threshold: 250000 },
            { rate: 0.069, threshold: 500000 },
            { rate: 0.0699, threshold: Infinity },
        ],
        DE: [
            { rate: 0.022, threshold: 2000 },
            { rate: 0.039, threshold: 5000 },
            { rate: 0.048, threshold: 10000 },
            { rate: 0.052, threshold: 20000 },
            { rate: 0.0555, threshold: 25000 },
            { rate: 0.066, threshold: 60000 },
            { rate: 0.0685, threshold: Infinity },
        ],
        FL: [],
        GA: [
            { rate: 0.01, threshold: 750 },
            { rate: 0.02, threshold: 2250 },
            { rate: 0.03, threshold: 3750 },
            { rate: 0.04, threshold: 5250 },
            { rate: 0.05, threshold: 7000 },
            { rate: 0.0575, threshold: Infinity },
        ],
        HI: [
            { rate: 0.014, threshold: 2400 },
            { rate: 0.032, threshold: 4800 },
            { rate: 0.055, threshold: 9600 },
            { rate: 0.064, threshold: 14400 },
            { rate: 0.068, threshold: 19200 },
            { rate: 0.072, threshold: 24000 },
            { rate: 0.076, threshold: 36000 },
            { rate: 0.079, threshold: 48000 },
            { rate: 0.0825, threshold: 150000 },
            { rate: 0.09, threshold: 175000 },
            { rate: 0.1, threshold: 200000 },
            { rate: 0.11, threshold: Infinity },
        ],
        ID: [
            { rate: 0.01, threshold: 1549 },
            { rate: 0.03, threshold: 3098 },
            { rate: 0.045, threshold: 4647 },
            { rate: 0.055, threshold: 6196 },
            { rate: 0.065, threshold: 7745 },
            { rate: 0.075, threshold: 9284 },
            { rate: 0.085, threshold: 11535 },
            { rate: 0.0925, threshold: Infinity },
        ],
        IL: [
            { rate: 0.0495, threshold: Infinity },
        ],
        IN: [
            { rate: 0.0323, threshold: Infinity },
        ],
        IA: [
            { rate: 0.0033, threshold: 1638 },
            { rate: 0.0067, threshold: 3276 },
            { rate: 0.0225, threshold: 6552 },
            { rate: 0.0414, threshold: 14742 },
            { rate: 0.0563, threshold: 24570 },
            { rate: 0.0596, threshold: 32760 },
            { rate: 0.0625, threshold: 49140 },
            { rate: 0.0744, threshold: 73710 },
            { rate: 0.0853, threshold: Infinity },
        ],
        KS: [
            { rate: 0.031, threshold: 15000 },
            { rate: 0.0525, threshold: 30000 },
            { rate: 0.057, threshold: Infinity },
        ],
        KY: [
            { rate: 0.05, threshold: Infinity },
        ],
        LA: [
            { rate: 0.0185, threshold: 12500 },
            { rate: 0.035, threshold: 50000 },
            { rate: 0.045, threshold: Infinity },
        ],
        ME: [
            { rate: 0.058, threshold: 23500 },
            { rate: 0.0675, threshold: 55250 },
            { rate: 0.0715, threshold: Infinity },
        ],
        MD: [
            { rate: 0.02, threshold: 1000 },
            { rate: 0.03, threshold: 2000 },
            { rate: 0.04, threshold: 3000 },
            { rate: 0.0475, threshold: 100000 },
            { rate: 0.05, threshold: 125000 },
            { rate: 0.0525, threshold: 150000 },
            { rate: 0.055, threshold: 250000 },
            { rate: 0.0575, threshold: Infinity },
        ],
        MA: [
            { rate: 0.05, threshold: Infinity },
        ],
        MI: [
            { rate: 0.0425, threshold: Infinity },
        ],
        MN: [
            { rate: 0.0535, threshold: 29170 },
            { rate: 0.068, threshold: 96730 },
            { rate: 0.0785, threshold: 182400 },
            { rate: 0.0985, threshold: Infinity },
        ],
        MS: [
            { rate: 0.04, threshold: 5000 },
            { rate: 0.05, threshold: 10000 },
            { rate: 0.06, threshold: Infinity },
        ],
        MO: [
            { rate: 0.015, threshold: 111 },
            { rate: 0.02, threshold: 1122 },
            { rate: 0.025, threshold: 2233 },
            { rate: 0.03, threshold: 3344 },
            { rate: 0.035, threshold: 4455 },
            { rate: 0.04, threshold: 5566 },
            { rate: 0.045, threshold: 6677 },
            { rate: 0.05, threshold: 7788 },
            { rate: 0.054, threshold: 8899 },
            { rate: 0.059, threshold: Infinity },
        ],
        MT: [
            { rate: 0.01, threshold: 3200 },
            { rate: 0.02, threshold: 6400 },
            { rate: 0.03, threshold: 9700 },
            { rate: 0.04, threshold: 13000 },
            { rate: 0.05, threshold: 16900 },
            { rate: 0.065, threshold: Infinity },
        ],
        NE: [
            { rate: 0.0246, threshold: 3499 },
            { rate: 0.0351, threshold: 20999 },
            { rate: 0.0501, threshold: 32999 },
            { rate: 0.0684, threshold: Infinity },
        ],
        NV: [],
        NH: [],
        NJ: [
            { rate: 0.014, threshold: 20000 },
            { rate: 0.0175, threshold: 35000 },
            { rate: 0.035, threshold: 40000 },
            { rate: 0.05525, threshold: 75000 },
            { rate: 0.0637, threshold: 500000 },
            { rate: 0.0897, threshold: 1000000 },
            { rate: 0.1075, threshold: Infinity },
        ],
        NM: [
            { rate: 0.017, threshold: 5500 },
            { rate: 0.032, threshold: 11000 },
            { rate: 0.047, threshold: 16000 },
            { rate: 0.049, threshold: 210000 },
            { rate: 0.059, threshold: Infinity },
        ],
        NY: [
            { rate: 0.04, threshold: 8500 },
            { rate: 0.045, threshold: 11700 },
            { rate: 0.0525, threshold: 13900 },
            { rate: 0.059, threshold: 21400 },
            { rate: 0.0621, threshold: 80650 },
            { rate: 0.0649, threshold: 215400 },
            { rate: 0.0685, threshold: 1077550 },
            { rate: 0.0965, threshold: Infinity },
        ],
        NC: [
            { rate: 0.0499, threshold: Infinity },
        ],
        ND: [
            { rate: 0.011, threshold: 40500 },
            { rate: 0.02, threshold: 97950 },
            { rate: 0.022, threshold: 202650 },
            { rate: 0.026, threshold: 445000 },
            { rate: 0.029, threshold: Infinity },
        ],
        OH: [
            { rate: 0.02765, threshold: 26250 },
            { rate: 0.03226, threshold: 52500 },
            { rate: 0.03688, threshold: 105000 },
            { rate: 0.0399, threshold: Infinity },
        ],
        OK: [
            { rate: 0.005, threshold: 1000 },
            { rate: 0.01, threshold: 2500 },
            { rate: 0.02, threshold: 3750 },
            { rate: 0.03, threshold: 4900 },
            { rate: 0.04, threshold: 7200 },
            { rate: 0.05, threshold: Infinity },
        ],
        OR: [
            { rate: 0.045, threshold: 3650 },
            { rate: 0.0675, threshold: 9200 },
            { rate: 0.0875, threshold: 125000 },
            { rate: 0.099, threshold: Infinity },
        ],
        PA: [
            { rate: 0.0307, threshold: Infinity },
        ],
        RI: [
            { rate: 0.0375, threshold: 68200 },
            { rate: 0.0475, threshold: 155050 },
            { rate: 0.0599, threshold: Infinity },
        ],
        SC: [
            { rate: 0.03, threshold: 3220 },
            { rate: 0.04, threshold: 6440 },
            { rate: 0.05, threshold: 9660 },
            { rate: 0.06, threshold: 12880 },
            { rate: 0.07, threshold: Infinity },
        ],
        SD: [],
        TN: [],
        TX: [],
        UT: [
            { rate: 0.0495, threshold: Infinity },
        ],
        VT: [
            { rate: 0.0355, threshold: 41050 },
            { rate: 0.068, threshold: 99600 },
            { rate: 0.078, threshold: 206950 },
            { rate: 0.0895, threshold: Infinity },
        ],
        VA: [
            { rate: 0.02, threshold: 3000 },
            { rate: 0.03, threshold: 5000 },
            { rate: 0.05, threshold: 17000 },
            { rate: 0.0575, threshold: Infinity },
        ],
        WA: [],
        WV: [
            { rate: 0.03, threshold: 10000 },
            { rate: 0.04, threshold: 25000 },
            { rate: 0.045, threshold: 40000 },
            { rate: 0.06, threshold: 60000 },
            { rate: 0.065, threshold: Infinity },
        ],
        WI: [
            { rate: 0.0354, threshold: 12660 },
            { rate: 0.0465, threshold: 25320 },
            { rate: 0.053, threshold: 280950 },
            { rate: 0.0765, threshold: Infinity },
        ],
        WY: [],
        DC: [
            { rate: 0.04, threshold: 10000 },
            { rate: 0.06, threshold: 40000 },
            { rate: 0.065, threshold: 60000 },
            { rate: 0.085, threshold: 350000 },
            { rate: 0.0925, threshold: 1000000 },
            { rate: 0.1075, threshold: Infinity },
        ],
        PR: [
            { rate: 0.00, threshold: 10000 },
            { rate: 0.07, threshold: 25000 },
            { rate: 0.14, threshold: 61000 },
            { rate: 0.25, threshold: Infinity },
        ],
    },
    socialSecurity: {
        rate: 0.062,
        maxEarnings: 160200,
    },
    medicare: {
        rate: 0.0145,
        additionalRate: 0.009,
        additionalThreshold: 200000,
    },
}

function calculateSocialSecurity(grossPay: number): number {
    const { rate, maxEarnings } = taxInfo.socialSecurity
    return Math.min(grossPay, maxEarnings) * rate
}

function calculateMedicare(grossPay: number): number {
    const { rate, additionalRate, additionalThreshold } = taxInfo.medicare
    let medicareTax = grossPay * rate

    if (grossPay > additionalThreshold) {
        medicareTax += (grossPay - additionalThreshold) * additionalRate
    }

    return medicareTax
}

export const calculateGrossEarningsUnitedStates = (netPay: number, state: string, additionalTaxRate: number = 0, tolerance: number = 100, maxIterations: number = 100): number => {
    let grossPay = netPay * 1.25
    let iteration = 0

    while (iteration < maxIterations) {
        iteration++

        const calculatedNetPay = calculateNetEarningsUnitedStates(grossPay, state, additionalTaxRate)
        const difference = netPay - calculatedNetPay

        if (Math.abs(difference) <= tolerance) {
            break
        }

        grossPay += difference / 2
    }

    if (iteration === maxIterations) {
        throw new Error("Unable to converge on a gross pay estimate. Please check the input values.")
    }

    return parseFloat(grossPay.toFixed(2))
}

export const calculateNetEarningsUnitedStates = (grossPay: number, state: string, additionalTaxRate: number = 0): number => {
    const federalBrackets = taxInfo.federal
    const stateBrackets = taxInfo.state[state]

    if (!stateBrackets) {
        throw new Error(`Unsupported state: ${state}`)
    }

    const socialSecurity = calculateSocialSecurity(grossPay)
    const medicare = calculateMedicare(grossPay)
    const taxableIncome = grossPay - socialSecurity - medicare

    const federalTax = calculateTax(taxableIncome, federalBrackets)
    const stateTax = calculateTax(taxableIncome, stateBrackets)
    const additionalTax = grossPay * additionalTaxRate

    return grossPay - federalTax - stateTax - additionalTax - socialSecurity - medicare
}
